import React, { useState } from "react";
import { ReactTyped } from "react-typed";
import "./header.css";

const Header = () => {
  /* Toggle Menu */
  const [Toggle, showMenu] = useState(false);

  return (
    <header className="header">
      <nav className="nav container">
        <div className="nav__logo__container">
          <a href="/" className="nav__logo">
            <img
              src={require("./../../assets/Logo_Picture.png")}
              alt="Website Logo"
              className="nav__logo__img"
            />
            <ReactTyped
              className="about__typed-text"
              strings={["Karl Xavier", "Bulba"]}
              typeSpeed={200}
              backSpeed={200}
              backDelay={8000}
              loop
              showCursor={false}
            />
          </a>
        </div>

        <div className={Toggle ? "nav__menu show-menu" : "nav__menu"}>
          <ul className="nav__list grid">
            <li className="nav__item">
              <a href="/" className="nav__link active-link">
                <i className="uil uil-estate nav__icon"></i>Home
              </a>
            </li>

            <li className="nav__item">
              <a href="/about" className="nav__link">
                <i className="uil uil-user nav__icon"></i>About
              </a>
            </li>

            <li className="nav__item">
              <a href="/skills" className="nav__link">
                <i className="uil uil-file-alt nav__icon"></i>Skills
              </a>
            </li>

            <li className="nav__item">
              <a href="/portfolio" className="nav__link">
                <i className="uil uil-scenery nav__icon"></i>Projects
              </a>
            </li>
          </ul>

          <i
            className="uil uil-times nav__close"
            onClick={() => showMenu(!Toggle)}
          ></i>
        </div>

        <div className="nav__toggle" onClick={() => showMenu(!Toggle)}>
          <i className="uil uil-apps"></i>
        </div>
      </nav>
    </header>
  );
};

export default Header;
