import React from "react";
import Backend from "./Backend";
import Frontend from "./Frontend";

const Skills = () => {
  return (
    <div className="skills section">
      <h2 className="section__title">Technical Skills</h2>
      <span className="section__subtitle">Skill Levels</span>

      <div className="tech__skills__container container">
        <div className="skills__container container grid">
          <Frontend />
          <Backend />
        </div>

        <div className="new__skills__container container">
          <div className="skills__content">
            <h3 className="skills__title">Currently Learning</h3>
            <ul>
              <div className="language__info">
                <li className="home__languages-icon">
                  <i class="devicon-godot-plain"></i>
                </li>
                <h5 className="language__name">GODOT</h5>
              </div>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Skills;
