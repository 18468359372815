import React from "react";
import "./skills.css";
const Backend = () => {
  return (
    <div className="skills__content">
      <h3 className="skills__title">Backend</h3>

      <div className="skills__box">
        <div className="skills__group">
          <div className="skills__data">
            <i className="bx bx-badge-check"></i>
            <div className="flex-grow">
              <h3 className="skills__name">
                <i class="devicon-c-plain"></i>
                <span> C </span> <span> / </span>
                <i class="devicon-cplusplus-plain"></i>
                <span> C++ </span>
              </h3>
              <div className="skills__level intermediate"></div>
            </div>
          </div>

          <div className="skills__data">
            <i className="bx bx-badge-check"></i>
            <div className="flex-grow">
              <h3 className="skills__name">
                <i class="devicon-express-original"></i>
                <span> Express </span>
              </h3>
              <div className="skills__level intermediate"></div>
            </div>
          </div>

          <div className="skills__data">
            <i className="bx bx-badge-check"></i>
            <div className="flex-grow">
              <h3 className="skills__name">
                <i class="devicon-java-plain"></i>
                <span> Java </span>
              </h3>
              <div className="skills__level advancedexpert"></div>
            </div>
          </div>

          <div className="skills__data">
            <i className="bx bx-badge-check"></i>
            <div className="flex-grow">
              <h3 className="skills__name">
                <i class="devicon-mongodb-plain"></i>
                <span> MongoDB </span>
              </h3>
              <div className="skills__level intermediate"></div>
            </div>
          </div>

          <div className="skills__data">
            <i className="bx bx-badge-check"></i>
            <div className="flex-grow">
              <h3 className="skills__name">
                <i class="devicon-mysql-original"></i>
                <span> MySQL </span>
              </h3>
              <div className="skills__level intermediateadvanced"></div>
            </div>
          </div>

          <div className="skills__data">
            <i className="bx bx-badge-check"></i>
            <div className="flex-grow">
              <h3 className="skills__name">
                <i class="devicon-postgresql-plain"></i>
                <span> PostgreSQL </span>
              </h3>
              <div className="skills__level basic"></div>
            </div>
          </div>

          <div className="skills__data">
            <i className="bx bx-badge-check"></i>
            <div className="flex-grow">
              <h3 className="skills__name">
                <i class="devicon-python-plain"></i>
                <span> Python </span>
              </h3>
              <div className="skills__level basiclearning"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Backend;
