import "./home.css";
import React from "react";
import Social from "./Social";
import Data from "./Data";
import Languages from "./Languages";

const Home = () => {
  return (
    <body className="home">
      <div className="home__container">
        <div className="home__content">
          <div className="social__img__container">
            <Social />
            <div className="home__img"></div>
          </div>
          <Data />
        </div>
        <Languages />
      </div>
    </body>
  );
};

export default Home;
