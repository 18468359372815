import React from "react";

const Languages = () => {
  return (
    <div className="home__languages">
      <ul className="home__languages__list">
        <div className="language__info">
          <li className="home__languages-icon">
            <i class="devicon-java-plain"></i>
          </li>
          <h5 className="language__name">JAVA</h5>
        </div>
        <div className="language__info">
          <li className="home__languages-icon">
            <i class="devicon-c-plain"></i>
          </li>
          <h5 className="language__name">C</h5>
        </div>
        <div className="language__info">
          <li className="home__languages-icon">
            <i class="devicon-cplusplus-plain"></i>
          </li>
          <h5 className="language__name">C++</h5>
        </div>
        <div className="language__info">
          <li className="home__languages-icon">
            <i class="devicon-python-plain"></i>
          </li>
          <h5 className="language__name">PYTHON</h5>
        </div>
        <div className="language__info">
          <li className="home__languages-icon">
            <i class="devicon-mysql-original"></i>
          </li>
          <h5 className="language__name">MYSQL</h5>
        </div>
        <div className="language__info">
          <li className="home__languages-icon">
            <i class="devicon-mongodb-plain"></i>
          </li>
          <h5 className="language__name">MONGODB</h5>
        </div>
        <div className="language__info">
          <li className="home__languages-icon">
            <i class="devicon-html5-plain"></i>
          </li>
          <h5 className="language__name">HTML</h5>
        </div>
        <div className="language__info">
          <li className="home__languages-icon">
            <i class="devicon-css3-plain"></i>
          </li>
          <h5 className="language__name">CSS</h5>
        </div>
        <div className="language__info">
          <li className="home__languages-icon">
            <i class="devicon-javascript-plain"></i>
          </li>
          <h5 className="language__name">JAVASCRIPT</h5>
        </div>
        <div className="language__info">
          <li className="home__languages-icon">
            <i class="devicon-react-original"></i>
          </li>
          <h5 className="language__name">REACT</h5>
        </div>
        <div className="language__info">
          <li className="home__languages-icon">
            <i class="devicon-express-original"></i>
          </li>
          <h5 className="language__name">EXPRESS</h5>
        </div>
      </ul>
    </div>
  );
};

export default Languages;
