import "./App.css";
import React from "react";
import Home from "./components/MainPage/Home";
import About from "./components/About/About";
import Skills from "./components/Skills/Skills";
import Header from "./components/Header/Header";
import Work from "./components/Work/Work";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

const App = () => {
  return (
    <div className="App">
      <Header />
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/skills" element={<Skills />} />
          <Route path="/projects" element={<Work />} />
        </Routes>
      </Router>
    </div>
  );
};

export default App;
