import React from 'react'

const Work = () => {
  return (
    <div className="work__container section">
      
    </div>
  )
}

export default Work