import React from "react";
import "./skills.css";
const Frontend = () => {
  return (
    <div className="skills__content">
      <h3 className="skills__title">Frontend</h3>

      <div className="skills__box">
        <div className="skills__group">
          <div className="skills__data">
            <i className="bx bx-badge-check"></i>
            <div className="flex-grow">
              <h3 className="skills__name">
                <i class="devicon-css3-plain"></i>
                <span> CSS </span>
              </h3>
              <div className="skills__level intermediate"></div>
            </div>
          </div>

          <div className="skills__data">
            <i className="bx bx-badge-check"></i>
            <div className="flex-grow">
              <h3 className="skills__name">
                <i class="devicon-git-plain"></i>
                <span> Git </span>
              </h3>
              <div className="skills__level basicintermediate"></div>
            </div>
          </div>

          <div className="skills__data">
            <i className="bx bx-badge-check"></i>
            <div className="flex-grow">
              <h3 className="skills__name">
                <i class="devicon-html5-plain"></i>
                <span> HTML </span>
              </h3>
              <div className="skills__level intermediateadvanced"></div>
            </div>
          </div>

          <div className="skills__data">
            <i className="bx bx-badge-check"></i>
            <div className="flex-grow">
              <h3 className="skills__name">
                <i class="devicon-javascript-plain"></i>{" "}
                <span> JavaScript </span>
              </h3>
              <div className="skills__level basic"></div>
            </div>
          </div>

          <div className="skills__data">
            <i className="bx bx-badge-check"></i>
            <div className="flex-grow">
              <h3 className="skills__name">
                <i class="devicon-nextjs-plain"></i>
                <span> NextJS </span>
              </h3>
              <div className="skills__level learning"></div>
            </div>
          </div>

          <div className="skills__data">
            <i className="bx bx-badge-check"></i>
            <div className="flex-grow">
              <h3 className="skills__name">
                <i class="devicon-react-original"></i>
                <span> React </span>
              </h3>
              <div className="skills__level intermediate"></div>
            </div>
          </div>

          <div className="skills__data">
            <i className="bx bx-badge-check"></i>
            <div className="flex-grow">
              <h3 className="skills__name">
                <i class="devicon-tailwindcss-original"></i>
                <span> Tailwind CSS </span>
              </h3>
              <div className="skills__level learning"></div>
            </div>
          </div>

          <div className="skills__data">
            <i className="bx bx-badge-check"></i>
            <div className="flex-grow">
              <h3 className="skills__name">
                <span>
                  <i class="devicon-typescript-plain"></i> TypeScript{" "}
                </span>
              </h3>
              <div className="skills__level semilearning"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Frontend;
